import React from "react";
import Card from './Card'
const TwoImageWithText = ({left_image, left_title, left_description, right_image, right_title, right_description}) => {
  return (
    <div className='container-fluid'>
        <div className='row'>
        <div className='col-lg-6'>
            <Card
              cardclassName={"card-reverse"}
              image={left_image}
              title={left_title}
              description={left_description}
            />
        </div>
        <div className='col-lg-6'>
            <Card
            image={right_image}
            title={right_title}
            description={right_description}
            />
        </div>
        </div>
    </div>
  );
};

export default TwoImageWithText;
