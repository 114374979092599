import React, { Fragment, useState, useContext, useEffect } from 'react';
import DataContext from '../context/dataContext';
import Footer from '../components/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import PromoLanding from '../components/PromoLanding';
import RegisterForm from '../components/RegisterForm';
const Home = () => {
  const dataContext = useContext(DataContext);
  const { lang } = dataContext;
  useEffect(() => {
    // getCompanies();
    // eslint-disable-next-line
  }, [lang]);
  const [hide, setHide] = useState('');
  // Set key for React Fullpage
  let anchors = [];

  anchors.push('footerr');

  return (
    <Fragment>
      <ReactFullpage
        licenseKey={'E2D9F03D-718B42A6-8BAC5EBE-085C8480'}
        responsiveWidth={1100}
        className='promo-slider'
        navigation={true}
        navigationPosition='right'
        lockAnchors={true}
        controlArrows={true}
        anchors={anchors}
        scrollingSpeed={700}
        onLeave={(origin) => {
          if (origin.anchor === 'footerr') {
            setHide('hide');
          } else {
            setHide('');
          }
        }}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className='promo-slider'>
                <div className='section'>
                  <div>
                    <PromoLanding fullpageApi={fullpageApi} />
                  </div>
                </div>
                <div className='section fp-auto-height' id='section-footer'>
                  <RegisterForm />
                  <Footer />
                </div>
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </Fragment>
  );
};

export default Home;
