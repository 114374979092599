import React from "react";
import { InView } from 'react-intersection-observer';
import { scroller } from "react-scroll";
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router-dom';

const PromoProjects = ({
  detailClass,
  fullpageApi,
  name,
  description,
  stream_id,
  slug,
  service,
  link,
  cover,
  location,
}) => {
  const handleClick = () => {
    fullpageApi
      ? fullpageApi.moveSectionDown()
      : scroller.scrollTo("myScrollToElement", {
        duration: 1000,
        delay: 50,
        smooth: "easeInOutQuart",
        offset: -50,
      });
  };
  const history = useHistory();
  const { t } = useTranslation();
  const controlVideo = (inView, entry) => {
    const video = $(entry.target).find('video');
    if (inView) {
      video.get(0).src = process.env.REACT_APP_STREAM_URL + video.attr('stream_id');
      // video.get(0).load();
      video.get(0).autoplay = true;
      console.warn("true >> ", process.env.REACT_APP_STREAM_URL + video.attr('stream_id'));
    } else {
      // video.get(0).pause()
      video.get(0).src = "";
      // console.warn("false >> ", $(entry.target).find('video').get(0).pause());
    }
  };
  console.warn("service >> ", service);
  return (
    <section
      className={`promo ${detailClass ? detailClass : null}`}
      style={{
        backgroundImage: `url(${cover ? process.env.REACT_APP_API_URL + cover.url : ''})`,
      }}
    >
      <div className='promo-slide-down'>
        <button
          type='button'
          className='promo-slide-down-button'
          onClick={handleClick}
        >
          <svg className='icon icon-angle-right'>
            <path
              d='M6.39835924,6.83847283 L11.8347912,1.30286072 C12.0550696,1.08199059 12.0550696,0.724872086 11.8347912,0.504001956 L11.5020624,0.17033341 C11.281784,-0.050490829 10.9255765,-0.050490829 10.7053439,0.17033341 L6,4.97289349 L1.29465609,0.165652597 C1.07442347,-0.0552175324 0.718215988,-0.0552175324 0.497937608,0.165652597 L0.165208785,0.499275253 C-0.055069595,0.720145382 -0.055069595,1.07730977 0.165208785,1.29813401 L5.60164076,6.83379202 C5.82191914,7.05461626 6.17808086,7.05461626 6.39835924,6.83847283 Z'
              id='angle-down'
              fill='#fff'
              fillRule='nonzero'
            ></path>
          </svg>
        </button>
        <button onClick={() => history.push(`/explore#${service}`)} className='back-to-dev'>
          <div className='back-to-dev-link-title'>
            <svg className='icon icon-long-arrow-left'>
              <path
                d='M11.531223,8.876426 L15.626401,4.798311 C15.791127,4.63355 15.791127,4.366446 15.626401,4.201651 L11.531223,0.12357 C11.366497,-0.04119 11.099358,-0.04119 10.934632,0.12357 L10.686033,0.372135 C10.521307,0.536896 10.521307,0.804 10.686033,0.96876 L13.63661,3.902343 L0.421874,3.902343 C0.188896,3.902343 0,4.091239 0,4.324217 L0,4.675779 C0,4.908757 0.188896,5.097653 0.421874,5.097653 L13.63661,5.097653 L10.686033,8.031236 C10.521307,8.195962 10.521307,8.463066 10.686033,8.627861 L10.934632,8.876426 C11.099358,9.041152 11.366497,9.041152 11.531223,8.876426 Z'
                id='long-arrow-left'
                fill='#fff'
                fillRule='nonzero'
                transform='translate(7.874973, 4.499985) scale(-1, 1) translate(-7.874973, -4.499985) '
              ></path>
            </svg>
            <div className='dev-name'>
              {'BACK'}
            </div>
          </div>
          {/* <div className='dev-name'>
              {' '}
              {navigationData && navigationData.our_service.name.toUpperCase()}
            </div> */}
        </button>
      </div>
      <div className='promo-content promo-details-content'>
        {/* <h6 className='promo-sub-title promo-companies-sub-title ' dangerouslySetInnerHTML={{ __html: sector.toUpperCase() }}></h6> */}
        <h2 style={{ fontSize: 32 }}>{name.toUpperCase()}</h2>
        <h3 className='promo-title' style={{ fontSize: 24, paddingTop: 0 }}>{location?.toUpperCase()}</h3>
        {/* <h2 className='promo-title'><img src={process.env.REACT_APP_API_URL + logo.url} alt=""/></h2> */}
        <NavLink to={`/projects/${service}/${slug}`} className='btn btn-outline-light promo-action-button'>
          {t('DETAILS')}
        </NavLink>
      </div>
      {stream_id && (
        <InView
          onChange={(inView, entry) => {
            controlVideo(inView, entry);
          }}
        >
          <video
            muted
            loop
            playsInline
            preload='none'
            className='promo-video'
            stream_id={stream_id}
            cover={`${process.env.REACT_APP_API_URL}${cover}`}
          >
            <source
              // src={process.env.REACT_APP_API_URL + stream_id}
              type='video/mp4'
            />
          </video>
        </InView>
      )}
    </section>
  );
};

export default PromoProjects;
