import React from "react";

const ImageCard = ({ image }) => {
  return (
    <div className='card'>
      <img src={process.env.REACT_APP_API_URL + image.url} alt='' />
    </div>
  );
};

export default ImageCard;
