import React, { Fragment, useContext } from 'react';
import EventListener, { withOptions } from 'react-event-listener';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DataContext from '../context/dataContext';
import {
  fixedNavigationClose,
  fixedNavigationOpen,
  handleScroll,
  scrollToSection,
} from '../animations/animation';

import { Filter, Map } from 'react-lodash';

const FixedNavigation = ({ price, navigationData, backgroundColor }) => {
  const history = useHistory();
  const dataContext = useContext(DataContext);
  const { money, currency } = dataContext;

  const { t } = useTranslation();
  console.warn("navigationData >> ", navigationData);
  return (
    <Fragment>
      <EventListener
        target='window'
        onScroll={withOptions(handleScroll, { passive: true, capture: false })}
      />
      <nav
        className='fixed-navigation'
        style={{ backgroundColor: backgroundColor }}
        id='fixed-navigation'
      >
        {/* {navigationData && navigationData.our_service && (
          <button
            type='button'
            className='fixed-navigation-closer'
            onClick={() => {
              fixedNavigationClose();
            }}
          >
            <svg className='icon icon-times'>
              <path
                d='M0.118819902,19.8811052 C0.198033171,19.9603684 0.30155051,20 0.405517924,20 C0.508585188,20 0.612552603,19.9603684 0.691765871,19.8811052 L10.000225,10.573082 L19.3082341,19.8811052 C19.3874474,19.9603684 19.4909647,20 19.5949322,20 C19.6979994,20 19.8019668,19.9603684 19.8811801,19.8811052 C20.0396066,19.7225788 20.0396066,19.465874 19.8811801,19.3082483 L10.573171,10.0002252 L19.8811801,0.691751672 C20.0396066,0.533675606 20.0396066,0.276970884 19.8811801,0.118894819 C19.7227536,-0.0391812471 19.4666607,-0.0396316063 19.3086842,0.118894819 L9.99977496,9.42691797 L0.691315795,0.118894819 C0.532889259,-0.0396316063 0.276796363,-0.0396316063 0.118819902,0.118894819 C-0.0391565586,0.277421243 -0.039606634,0.534125965 0.118819902,0.691751672 L9.42682899,10.0002252 L0.118819902,19.3082483 C-0.039606634,19.4663244 -0.039606634,19.7230291 0.118819902,19.8811052 Z'
                id='times'
                fill='#fff'
                xlinkHref='#icon-times'
                fillRule='nonzero'
              ></path>
            </svg>
          </button>
        )} */}
        {navigationData && navigationData.our_service && (
          <button onClick={() => history.push(`/projects/${navigationData.our_service.slug}/#${navigationData.slug}`)} className='back-to-dev'>
            <div className='back-to-dev-link-title'>
              <svg className='icon icon-long-arrow-left'>
                <path
                  d='M11.531223,8.876426 L15.626401,4.798311 C15.791127,4.63355 15.791127,4.366446 15.626401,4.201651 L11.531223,0.12357 C11.366497,-0.04119 11.099358,-0.04119 10.934632,0.12357 L10.686033,0.372135 C10.521307,0.536896 10.521307,0.804 10.686033,0.96876 L13.63661,3.902343 L0.421874,3.902343 C0.188896,3.902343 0,4.091239 0,4.324217 L0,4.675779 C0,4.908757 0.188896,5.097653 0.421874,5.097653 L13.63661,5.097653 L10.686033,8.031236 C10.521307,8.195962 10.521307,8.463066 10.686033,8.627861 L10.934632,8.876426 C11.099358,9.041152 11.366497,9.041152 11.531223,8.876426 Z'
                  id='long-arrow-left'
                  fill='#fff'
                  fillRule='nonzero'
                  transform='translate(7.874973, 4.499985) scale(-1, 1) translate(-7.874973, -4.499985) '
                ></path>
              </svg>
              <div className='dev-name'>
              {'BACK'}
              {/* {navigationData && navigationData.our_service.name.toUpperCase()} */}
            </div>
            </div>
            {/* <div className='dev-name'>
              {' '}
              {navigationData && navigationData.our_service.name.toUpperCase()}
            </div> */}
          </button>
        )}
        <div className='container-fluid'>
          <Filter
            collection={navigationData && navigationData.sections}
            predicate={{ __component: 'components.section-name' }}
          >
            {(value) => (
              <Map
                collection={navigationData && value}
                iteratee={(i) => (
                  <button
                    id={'section-nav-' + i.id}
                    className='fixed-navigation-item'
                    key={i.id}
                    onClick={() => {
                      fixedNavigationClose();
                      scrollToSection('section-' + i.id);
                    }}
                  >
                    {i.name.toUpperCase()}
                  </button>
                )}
              />
            )}
          </Filter>
        </div>
        {price && (
          <div className='starting-price'>
            <h6> {t('STARTING_PRICE')}</h6>
            <div className='starting-price-info'>
              {money === 'USD'
                ? '$ ' + parseFloat(`${price}`).toLocaleString()
                : '£' +
                  (parseFloat(`${price}`) * currency).toLocaleString()}{' '}
              {}
            </div>
          </div>
        )}
      </nav>
      <div
        className='fixed-navigation-mobile-handler'
        style={{ backgroundColor: backgroundColor }}
        onClick={() => {
          fixedNavigationOpen();
        }}
      >
        <span className='fixed-navigation-mobile-handler-span'>
          {t('DISCOVER')}
        </span>
      </div>
    </Fragment>
  );
};

export default FixedNavigation;
