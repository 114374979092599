import React from "react";
import Card from './Card'
const ImageWithText = ({image, title, description, image_position}) => {
  return (
    <div className='container-fluid'>
      <Card
        cardclassName={'card-row ' + (image_position === 'right' ? 'card-reverse' : '')  }
        image={image}
        title={title}
        description={description}
      />
    </div>
  );
};

export default ImageWithText;
