import ImageSlider from './components/page-components/ImageSlider';
import HeroContentShort from './components/page-components/HeroContentShort';
import HeroContent from './components/page-components/HeroContent';
import HeroVideo from './components/page-components/HeroVideo';
import HeroVideoNoImg from './components/page-components/HeroVideoNoImg';
import FullImageCard from './components/page-components/FullImageCard';
import TwoImageWithText from './components/page-components/TwoImageWithText';
import ImageWithText from './components/page-components/ImageWithText';
import TwoImage from './components/page-components/TwoImage';
import Swiper from './components/page-components/Swiper';
import ImageGrids from './components/page-components/ImageGrids';

const components = [
  {
    name: 'components.video-with-background',
    component: HeroVideo,
  },
  {
    name: 'components.information',
    component: HeroContent,
  },
  {
    name: 'components.image-slider',
    component: ImageSlider,
  },
  {
    name: 'components.image-grids',
    component: ImageGrids,
  },
  {
    name: 'components.title',
    component: HeroContentShort,
  },
  {
    name: 'components.video',
    component: HeroVideoNoImg,
  },
  {
    name: 'components.two-column-image-with-text',
    component: TwoImageWithText,
  },
  {
    name: 'components.image',
    component: FullImageCard,
  },
  {
    name: 'components.image-with-text',
    component: ImageWithText,
  },
  {
    name: 'components.two-image',
    component: TwoImage,
  },
  {
    name: 'components.image-gallery',
    component: Swiper,
  },
];

export default components;
