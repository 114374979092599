import React, { Fragment, useEffect, useContext } from 'react';
import Footer from '../components/Footer';
import DataContext from '../context/dataContext';
import ReactFullpage from '@fullpage/react-fullpage';
import RegisterForm from '../components/RegisterForm';
import PromoProjects from '../components/PromoProjects';
import { InView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';

const Projects = ({ location }) => {
  const dataContext = useContext(DataContext);
  const { getStaticPagesByService, staticPagesByService, filteredState } = dataContext;
  const { service } = useParams();
  useEffect(() => {
    getStaticPagesByService(service);
    // getFilteredProjects();
    // eslint-disable-next-line
  }, [filteredState]);
  return (
    <Fragment>
      <ReactFullpage
        //fullpage options
        licenseKey={'E2D9F03D-718B42A6-8BAC5EBE-085C8480'}
        navigation={true}
        navigationPosition='right'
        verticalCentered={false}
        responsiveWidth={0}
        scrollingSpeed={700} /* Options here */
        // anchors={_.map(staticPagesByService, service => service.slug)}
        render={({ state, fullpageApi }) => {
          return (
            <InView>
              <div className='promo-slider'>
                {staticPagesByService?.map((projectItem) => (
                  <div className='section fp-auto-height' key={projectItem.id}>
                    <PromoProjects
                      fullpageApi={fullpageApi}
                      name={projectItem.name}
                      location={projectItem.location}
                      slug={projectItem.slug}
                      service={service}
                      description={projectItem.description}
                      stream_id={projectItem.stream_id}
                      cover={projectItem.cover}
                    />
                  </div>
                ))}
                <div className='section fp-auto-height' id='section-footer'>
                  <RegisterForm />
                  <Footer />
                </div>
              </div>
            </InView>
          );
        }}
      />
      {/* <DesktopGetInTouchBotton /> */}
      {/* <FixedGetInTouchButton /> */}
    </Fragment>
  );
};

export default Projects;
