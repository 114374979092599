import React from "react";

const ImageGrids = ({ images, image_height }) => {
    return (
        <div className='container'>
            <div className='d-flex flex-wrap justify-content-center'>
                {images &&
                    images.map((image) => (
                        <div className='p-md-4 p-2'>
                            <img src={process.env.REACT_APP_API_URL + image.url} alt='' height={image_height}  />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ImageGrids;