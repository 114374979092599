import React, { Fragment, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
const Swiper = ({ images }) => {
  const swiperimages =
    images && images.map((e) => process.env.REACT_APP_API_URL + e.url);
  const [state, setState] = useState({
    photoIndex: 0,
    isOpen: false,
  });
  const { photoIndex, isOpen } = state;
  return (
    <Fragment>
      <div className='swiper'>
        <div className='row m-0'>
          <div className='col-6 p-0'>
            <img
              className='swiper__image'
              src={images && process.env.REACT_APP_API_URL + images[0].url}
              alt=''
              onClick={() => setState({ ...state, isOpen: true })}
            />
            <img
              className='swiper__image'
              src={images && process.env.REACT_APP_API_URL + images[1].url}
              alt=''
              onClick={() => setState({ ...state, isOpen: true })}
            />
          </div>
          <div className='col-6 p-0'>
            <img
              className='swiper__image vertical__image'
              src={images && process.env.REACT_APP_API_URL + images[2].url}
              alt=''
              onClick={() => setState({ ...state, isOpen: true })}
            />
          </div>
        </div>
      </div>
      <div>
        {isOpen && (
          <Lightbox
            mainSrc={swiperimages[photoIndex]}
            nextSrc={swiperimages[(photoIndex + 1) % swiperimages.length]}
            prevSrc={
              swiperimages[
                (photoIndex + swiperimages.length - 1) % swiperimages.length
              ]
            }
            onCloseRequest={() => setState({ ...state, isOpen: false })}
            onMovePrevRequest={() =>
              setState({
                ...state,
                photoIndex:
                  (photoIndex + swiperimages.length - 1) % swiperimages.length,
              })
            }
            onMoveNextRequest={() =>
              setState({
                ...state,
                photoIndex: (photoIndex + 1) % swiperimages.length,
              })
            }
          />
        )}
      </div>
    </Fragment>
  );
};

export default Swiper;
