export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_LANG = 'GET_LANG';
export const GET_CURRENCY = 'GET_CURRENCY';
export const GET_SERVICES = 'GET_SERVICES';
export const GET_MONEYTYPE = 'GET_MONEYTYPE';
export const GET_STATICPAGES = 'GET_STATICPAGES';
export const GET_STATICPAGE = 'GET_STATICPAGE';
export const GET_STATICPAGESBYSERVICE = 'GET_STATICPAGESBYSERVICE';
export const GET_STATICPAGESBYBLOG = 'GET_STATICPAGESBYBLOG';
export const GET_FILTEREDSTATE = 'GET_FILTEREDSTATE';
export const GET_MENUS = 'GET_MENUS';
