import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        marginRight: '50px',
        zIndex: '3',
      }}
      onClick={onClick}
    >
      <svg className='icon icon-angle-right sliderright'>
        <path
          d='M4.39835924,9.83847283 L9.83479121,4.30286072 C10.0550696,4.08199059 10.0550696,3.72487209 9.83479121,3.50400196 L9.50206239,3.17033341 C9.28178401,2.94950917 8.92557653,2.94950917 8.70534391,3.17033341 L4,7.97289349 L-0.705343913,3.1656526 C-0.925576526,2.94478247 -1.28178401,2.94478247 -1.50206239,3.1656526 L-1.83479121,3.49927525 C-2.0550696,3.72014538 -2.0550696,4.07730977 -1.83479121,4.29813401 L3.60164076,9.83379202 C3.82191914,10.0546163 4.17808086,10.0546163 4.39835924,9.83847283 Z'
          id='angle-right'
          fill='#fff'
          fillRule='nonzero'
          transform='translate(4.000000, 6.500000) rotate(-90.000000) translate(-4.000000, -6.500000) '
        ></path>
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        marginLeft: '50px',
        zIndex: '3',
      }}
      onClick={onClick}
    >
      <svg className='icon icon-angle-left sliderleft'>
        <path
          d='M4.39835924,9.83847283 L9.83479121,4.30286072 C10.0550696,4.08199059 10.0550696,3.72487209 9.83479121,3.50400196 L9.50206239,3.17033341 C9.28178401,2.94950917 8.92557653,2.94950917 8.70534391,3.17033341 L4,7.97289349 L-0.705343913,3.1656526 C-0.925576526,2.94478247 -1.28178401,2.94478247 -1.50206239,3.1656526 L-1.83479121,3.49927525 C-2.0550696,3.72014538 -2.0550696,4.07730977 -1.83479121,4.29813401 L3.60164076,9.83379202 C3.82191914,10.0546163 4.17808086,10.0546163 4.39835924,9.83847283 Z'
          id='angle-left'
          fill='#fff'
          fillRule='nonzero'
          transform='translate(4.000000, 6.500000) scale(-1, 1) rotate(-90.000000) translate(-4.000000, -6.500000) '
        ></path>
      </svg>
    </div>
  );
}

const ImageSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 2000,
  };
  return (
    <Slider {...settings}>
      {images &&
        images.map((image) => (
          <img
            src={process.env.REACT_APP_API_URL + image.url}
            key={image.id}
            alt=''
          />
        ))}
    </Slider>
  );
};

export default ImageSlider;
