import React from "react";
import { InView } from 'react-intersection-observer';
import { scroller } from "react-scroll";
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router-dom';

const PromoBlogs = ({
  detailClass,
  fullpageApi,
  name,
  description,
  stream_id,
  slug,
  blog,
  link,
  cover,
  location,
}) => {
  const handleClick = () => {
    fullpageApi
      ? fullpageApi.moveSectionDown()
      : scroller.scrollTo("myScrollToElement", {
        duration: 1000,
        delay: 50,
        smooth: "easeInOutQuart",
        offset: -50,
      });
  };
  const history = useHistory();
  const { t } = useTranslation();
  const controlVideo = (inView, entry) => {
    const video = $(entry.target).find('video');
    if (inView) {
      video.get(0).src = process.env.REACT_APP_STREAM_URL + video.attr('stream_id');
      // video.get(0).load();
      video.get(0).autoplay = true;
      console.warn("true >> ", process.env.REACT_APP_STREAM_URL + video.attr('stream_id'));
    } else {
      // video.get(0).pause()
      video.get(0).src = "";
      // console.warn("false >> ", $(entry.target).find('video').get(0).pause());
    }
  };

  return (
    <section
      className={`promo ${detailClass ? detailClass : null}`}
      style={{
        backgroundImage: `url(${cover ? process.env.REACT_APP_API_URL + cover.url : ''})`,
      }}
    >
      <div className='promo-slide-down'>
        <button
          type='button'
          className='promo-slide-down-button'
          onClick={handleClick}
        >
          <svg className='icon icon-angle-right'>
            <path
              d='M6.39835924,6.83847283 L11.8347912,1.30286072 C12.0550696,1.08199059 12.0550696,0.724872086 11.8347912,0.504001956 L11.5020624,0.17033341 C11.281784,-0.050490829 10.9255765,-0.050490829 10.7053439,0.17033341 L6,4.97289349 L1.29465609,0.165652597 C1.07442347,-0.0552175324 0.718215988,-0.0552175324 0.497937608,0.165652597 L0.165208785,0.499275253 C-0.055069595,0.720145382 -0.055069595,1.07730977 0.165208785,1.29813401 L5.60164076,6.83379202 C5.82191914,7.05461626 6.17808086,7.05461626 6.39835924,6.83847283 Z'
              id='angle-down'
              fill='#fff'
              fillRule='nonzero'
            ></path>
          </svg>
        </button>
      </div>
      <div className='promo-content promo-details-content'>
        {/* <h6 className='promo-sub-title promo-companies-sub-title ' dangerouslySetInnerHTML={{ __html: sector.toUpperCase() }}></h6> */}
        <h2  style={{ fontSize:38}}>{name.toUpperCase()}</h2>
        <h3 className='promo-title' style={{ fontSize:24, paddingTop: 0}}>{location?.toUpperCase()}</h3>
        {/* <h2 className='promo-title'><img src={process.env.REACT_APP_API_URL + logo.url} alt=""/></h2> */}
        <NavLink to={`/blogs/${slug}`} className='btn btn-outline-light promo-action-button'>
          {t('DETAILS')}
        </NavLink>
      </div>
      {stream_id && (
        <InView
          onChange={(inView, entry) => {
            controlVideo(inView, entry);
          }}
        >
          <video
            muted
            loop
            playsInline
            preload='none'
            className='promo-video'
            stream_id={stream_id}
            cover={`${process.env.REACT_APP_API_URL}${cover}`}
          >
            <source
              // src={process.env.REACT_APP_API_URL + stream_id}
              type='video/mp4'
            />
          </video>
        </InView>
      )}
    </section>
  );
};

export default PromoBlogs;
