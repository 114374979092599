import {
  GET_PROJECTS,
  GET_LANG,
  GET_CURRENCY,
  GET_SERVICES,
  GET_MONEYTYPE,
  GET_STATICPAGES,
  GET_STATICPAGESBYSERVICE,
  GET_STATICPAGE,
  GET_FILTEREDSTATE,
  GET_MENUS,
  GET_STATICPAGESBYBLOG,
} from './types';

export default (state, action) => {
  switch (action.type) {
    case GET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };

    case GET_FILTEREDSTATE:
      return {
        ...state,
        filteredState: action.payload,
      };
    case GET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case GET_STATICPAGES:
      return {
        ...state,
        staticPages: action.payload,
      };
    case GET_STATICPAGESBYSERVICE:
      return {
        ...state,
        staticPagesByService: action.payload,
      };
    case GET_STATICPAGESBYBLOG:
      return {
        ...state,
        staticPagesByBlog: action.payload,
      };
    case GET_STATICPAGE:
      return {
        ...state,
        staticPage: action.payload,
      };
    case GET_MENUS:
      return {
        ...state,
        menus: action.payload,
      };
    case GET_MONEYTYPE:
      return {
        ...state,
        money: action.payload,
      };
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case GET_SERVICES:
      return {
        ...state,
        services: action.payload,
      };

    default:
      return state;
  }
};
