import React from "react";
import ImageCard from './ImageCard'

const TwoImage = ({ left_image, right_image }) => {
  return (
    <div className='container-fluid'>
    <div className='row'>
      <div className='col-lg-6'>
        {left_image && (
          <ImageCard image={left_image} />
        )}
      </div>
      <div className='col-lg-6'>
        {right_image && (
          <ImageCard image={right_image} />
        )}
      </div>
    </div>
  </div>
  );
};

export default TwoImage;
