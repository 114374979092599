import React from 'react';

const HeroVideoNoImg = ({ video }) => {
  return (
    <div className='hero-video no-video-image'>
      <div className='container-fluid'>
        <div className='hero-video-holder'>
          <a
            href={process.env.REACT_APP_API_URL + video.url}
            className='video-modal-handler'
          >
            <svg
              className='icon icon-play'
              xmlns='http://www.w3.org/2000/svg'
              height='60'
              viewBox='0 0 24 24'
              width='60'
            >
              <path
                d='M10,0 C15.52,0 20,4.48 20,10 C19.992,15.52 15.52,19.992 10,20 C4.48,20 0,15.52 0,10 C0,4.48 4.48,0 10,0 Z M10,0.44 C4.72,0.44 0.44,4.72 0.44,10 C0.44,15.28 4.72,19.56 10,19.56 C15.28,19.56 19.56,15.28 19.56,10 C19.56,4.72 15.28,0.44 10,0.44 Z M8.336,5.808 L12.544,10.016 L8.384,14.176 L8.336,5.808 Z M8.776,6.872 L8.816,13.136 L11.936,10.016 L8.776,6.872 Z'
                id='play'
                fill='#fff'
                fillRule='nonzero'
              ></path>
            </svg>
          </a>
          <video data-keepplaying autoPlay muted playsInline>
            <source
              src={process.env.REACT_APP_API_URL + video.url}
              type='video/mp4'
            />
          </video>
        </div>
      </div>
    </div>
  );
};

export default HeroVideoNoImg;
