import React from "react";

const HeroContentShort = ({ sub_title, title }) => {
  return (
    <div className='hero-content'>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-lg-9'>
            <div className='hero-content-title'>
              <h6>{sub_title}</h6>
              <h3>{title}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroContentShort;
