import React, { Fragment, useEffect, useContext } from 'react';
import DataContext from '../context/dataContext';
import { InView } from 'react-intersection-observer';
import { fixedActiveNavigationPartDesktop } from '../animations/animation';
import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import Promo from '../components/Promo';
import FixedNavigation from '../components/FixedNavigation';
import RegisterForm from '../components/RegisterForm';
import Footer from '../components/Footer';

import DesktopGetInTouchBotton from '../components/DesktopGetInTouchBotton';
import FixedGetInTouchButton from '../components/FixedGetInTouchButton';
import { Map, Slice } from 'react-lodash';
import _ from 'lodash';
import components from '../ComponentsConfig';

import { useParams } from 'react-router-dom';

gsap.registerPlugin(TextPlugin);

const StaticPages = () => {
  const { t } = useTranslation();

  const dataContext = useContext(DataContext);
  const { getStaticPage, staticPage } = dataContext;
  const { page } = useParams();
  useEffect(() => {
    getStaticPage(page);
    // eslint-disable-next-line
  }, []);
  let pageData,
    sectionsIndex,
    slicer = [];
  // console.warn("staticPages >> ", staticPages, page);

  if (staticPage) {
    pageData = staticPage[0];
     console.warn("staticPage >> ", pageData);

    if (pageData) {
          // console.warn("pageData.sections >> ", pageData.sections);
      const sections = _.filter(pageData.sections, {
        __component: 'components.section-name',
      });
        //  console.warn("sections >> ", sections);
      sectionsIndex = _.map(sections, (s) => {
        return _.findIndex(pageData.sections, s);
      });
      sectionsIndex.push(pageData.sections.length);

      if (sections.length > 2) {
        for (let index = 0; sectionsIndex.length != 1; index++) {
          slicer.push(_.take(sectionsIndex, 2));
          sectionsIndex = _.drop(sectionsIndex);
        }
      } else {
        slicer.push(_.take(sectionsIndex, 2));
      }
    }
  }

  const fixedNavigaitonMobileHandler = (entry, name) => {
    if (entry === true) {
      gsap.to('.fixed-navigation-mobile-handler', {
        duration: 0.3,
        text: `${t($(name)[0].innerHTML)}`,
      });
    }

    // console.log($(name)[0].innerHTML);
  };
  return (
    <Fragment>
      <Promo
        detailClass='detail-promo'
        src={pageData && pageData.cover.url}
        title={pageData && pageData.name}
        location={pageData && pageData.location}
        video={pageData && pageData.video && pageData.video.url}
        stream_id={pageData && pageData.stream_id}
        hasButton={false}
        buttonTitle={false}
      />
      <main
        className='main-content'
        style={{ backgroundColor: pageData && pageData.color }}
      >
        <FixedNavigation
          price={false}
          moneyType={false}
          navigationData={pageData}
          backgroundColor={pageData && pageData.color}
        />
        {/* <FixedGetInTouchButton /> */}
        {/* <DesktopGetInTouchBotton /> */}
        <Map
          collection={pageData && slicer}
          iteratee={(i) => (
            <Slice array={pageData.sections} start={i[0] + 1} end={i[1]}>
              {(value) => (
                <InView
                  onChange={(inView, entry) => {
                    fixedActiveNavigationPartDesktop(
                      'section-nav-' + pageData.sections[i[0]].id,
                      entry.isIntersecting
                    );
                    fixedNavigaitonMobileHandler(
                      entry.isIntersecting,
                      '#section-nav-' + pageData.sections[i[0]].id
                    );
                  }}
                >
                  <div
                    className='detail-section'
                    id={'section-' + pageData.sections[i[0]].id}
                    name={'section-' + pageData.sections[i[0]].id}
                  >
                    {value.map((comp) => {
                      const Component = components.find(
                        (c) => c.name === comp.__component
                      ).component;
                      return (
                        <Component {...comp}>{comp.__component}</Component>
                      );
                    })}
                  </div>
                </InView>
              )}
            </Slice>
          )}
        />
      </main>
      <RegisterForm />
      <Footer />
      {/* <FixedGetInTouchButton /> */}
    </Fragment>
  );
};

export default StaticPages;
