import React, { useState, useContext } from 'react';
import DataContext from '../context/dataContext';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { phoneOptions } from '../filteroptions/options';
import { InView } from 'react-intersection-observer';
import { handleScroll } from '../animations/animation';
import axios from 'axios';

const RegisterForm = () => {
  const { t } = useTranslation();

  const [selectedPhone, setSelectedPhone] = useState('+93');

  // Register Form State
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });
  // Phone State
  const handleChangePhone = (selectedPhone) => {
    setSelectedPhone(selectedPhone);
  };
  // İnfo Handle State
  const [info, setInfo] = useState('');

  // Form Submit
  const handleSubmit = (e) => {
    if(user.name && (user.phone || user.email)){
      axios.post(`${process.env.REACT_APP_API_URL}/postsmails`, user);
      setUser({
        name: '',
        email: '',
        phone: '',
      });
      alert("Thank you for your interest. We will contact you as soon as posible.");
    }else{
      alert("Please provide your email or phone with your name to reach you.");
    }
  };
  return (
    <InView
      onChange={(inView, entry) => {
        handleScroll(inView);
      }}
    >

      <div id='register-form'>
        <div className='container'>
          {/* <h3>{t('GET_IN_TOUCH')} </h3> */}
          <div className='row justify-content-center'>
            <div className='col-lg-5'>
              {/* <form onSubmit={handleSubmit}>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={t('ENTER_FULL_NAME')}
                    name='name'
                    value={user.name}
                    onChange={onChange}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='email'
                    className='form-control'
                    placeholder={t('ENTER_YOUR_EMAIL')}
                    name='email'
                    value={user.email}
                    onChange={onChange}
                  />
                </div>
                <div className='form-group'>
                  <div className='form-row'>
                    <div className='col-3 px-1 border-white'>
                      <Select
                        isSearchable={false}
                        onChange={handleChangePhone}
                        options={phoneOptions}
                        classNamePrefix='phonenumberfilter'
                        placeholder={
                          selectedPhone.value ? selectedPhone.value : '+93'
                        }
                        value={selectedPhone.value}
                      />
                    </div>
                    <div className='col-9'>
                      <input
                        type='number'
                        className='form-control'
                        placeholder={t('ENTER_PHONE_NUMBER')}
                        name='phone'
                        value={user.phone}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <button type='button' className='btn btn-light btn-block' onClick={(e) => handleSubmit()}>
                  {t('SUBMIT')}
                </button>
              </form> */}

              <div id='get-in-touch'>
                <h6> {t('GET_IN_TOUCH')}</h6>
                <div className='get-in-touch-items'>
                  <a href='tel:+97143708909' className='get-in-touch-item'>
                    <svg className='icon icon-phone'>
                      <path
                        d='M21.4479695,24 C22.0927865,24 22.7322933,23.4896763 22.8753927,22.8609271 L23.9624627,18.1405244 C23.983154,18.0508927 23.9999542,17.9034445 23.9999542,17.8114325 C23.9999542,17.2792731 23.6030205,16.6769831 23.1139832,16.467049 L18.0331312,14.2873241 C17.8806017,14.2223206 17.6222813,14.1695395 17.4564764,14.1695395 C17.0737794,14.1695395 16.5656072,14.4091829 16.3221643,14.7045371 L14.2549139,17.2311155 C11.4867237,15.8696572 8.13505786,12.5179914 6.77355378,9.74975538 L9.30017796,7.6825507 C9.5954406,7.43915358 9.83512977,6.93111874 9.83512977,6.54846751 C9.83512977,6.38270844 9.78234871,6.12447952 9.71734515,5.9720416 L7.53762024,0.886016796 C7.32626712,0.396933694 6.72232914,0 6.18952886,0 C6.09724212,0 5.94938191,0.0168002152 5.85947558,0.0375372655 L1.13440363,1.12978014 C0.508217953,1.27141465 0,1.90739663 0,2.54942121 L0,2.55477713 C0,14.4279515 9.62359354,24 21.4452229,24 L21.4479695,24 Z M21.4170699,22.4999743 C10.4251973,22.4859207 1.51407934,13.5795178 1.50469502,2.58759935 L6.17355263,1.50945584 L8.32983965,6.54343203 L4.91729022,9.33254241 C7.1860517,14.1701346 9.81581181,16.8092333 14.6721269,19.0874248 L17.4612372,15.6748754 L22.4952134,17.8311624 L21.4170699,22.4999743 Z'
                        id='phone'
                        fill='#fff'
                        fillRule='nonzero'
                      ></path>
                    </svg>
                    {t('CALL')}
                  </a>
                  <a
                    href='https://www.google.com.tr/maps/place/Ayana+holding/@25.2488657,55.3075912,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f43445200bf07:0xe3521a98c6bc3182!8m2!3d25.2488657!4d55.3097799'
                    target="_blank"
                    className='get-in-touch-item'
                  >
                    <svg className='icon icon-location'>
                    <path d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602"/>
                    </svg>
                    {t('LOCATION')}
                  </a>
                  <a
                    href='mailto:info@vxstudio.com'
                    className='get-in-touch-item'
                  >
                    <svg className='icon icon-mail'>
                      <path
                        d='M29,24 C30.65625,24 32,22.65625 32,21 L32,3 C32,1.34375 30.65625,0 29,0 L3,0 C1.34375,0 0,1.34375 0,3 L0,21 C0,22.65625 1.34375,24 3,24 L29,24 Z M16,16 C14.5499878,16.0250244 12.4625244,14.0062256 11.4124756,13.1687622 C5.32501221,8.33752441 3.36877441,6.74377441 2,5.58752441 L2,3 C2,2.45001221 2.45001221,2 3,2 L29,2 C29.5499878,2 30,2.45001221 30,3 L30,5.58752441 C28.6312256,6.74377441 26.6749878,8.33752441 20.5875244,13.1687622 C19.53125,14.0062256 17.4500122,16.0250244 16,16 Z M29,22 L3,22 C2.45001221,22 2,21.5499878 2,21 L2,8.1875 C3.42498779,9.35626221 5.67498779,11.1624756 10.1687622,14.7312622 C11.4500122,15.7562256 13.7125244,18.0125122 16,18 C18.2750244,18.0187378 20.5187378,15.78125 21.8312378,14.7312622 C26.3250122,11.1624756 28.5750122,9.35626221 30,8.1875 L30,21 C30,21.5499878 29.5499878,22 29,22 Z'
                        id='mail'
                        fill='#fff'
                        fillRule='nonzero'
                      ></path>
                    </svg>
                    {t('MAIL')}
                  </a>
                </div>
                <div className='get-in-touch-items' style={{marginTop: 30, justifyContent: 'center'}}>
                <a
                    href='https://www.linkedin.com/company/ve-experts/'
                    className='get-in-touch-item'
                    target="_blank"
                  >
                    <svg className="icon" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn icon</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
                    {/* {t('LINKEDIN')} */}
                  </a>
                  <a
                    href='#'
                    className='get-in-touch-item'
                    target="_blank"
                  >
                    <svg className="icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Facebook icon</title><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
                    {/* {t('FACEBOOK')} */}
                  </a>
                  <a
                    href='https://www.instagram.com/vx_studio/'
                    className='get-in-touch-item'
                    target="_blank"
                  >
                    <svg className="icon" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram icon</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>
                    {/* {t('INSTAGRAM')} */}
                  </a>
                </div>
                <div className="get-in-touch-items">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InView>
  );
};

export default RegisterForm;
