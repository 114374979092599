import React, { useContext } from 'react';
import DataContext from '../context/dataContext';
import { handleMenuAnimation } from "../animations/animation";
import _ from 'lodash';
import $ from 'jquery';

const MobileMenu = ({ handleOpen }) => {
  const dataContext = useContext(DataContext);
  const { menus } = dataContext;
  let mainMenu = [], sortedMenu;
  if (menus) {
    console.warn("menus >> ", menus);
    sortedMenu = _.orderBy(menus, ['order'], ['asc'])
    sortedMenu.map((item) => {
      mainMenu.push(item);
      return mainMenu; // I added return
    });
  }
  return (
    <nav id='mobile-menu'>
      {mainMenu.length > 0 && (
        <ul>
          {mainMenu.map((item, index) => (
            <li key={item.id}>
              {item.nasted_menus.length > 0 ? (
                <>
                  <a href={'#'} onClick={()=>{
                    $(`#nasted-${index}`).toggle('slow');
                    console.warn("$(`nasted-${index}`) >> ", `nasted-${index}`, $(`#nasted-${index}`));
                  }}>{item.name}</a>
                  <ul className="nasted-menu" id={`nasted-${index}`}>
                    {_.orderBy(item.nasted_menus, ['order'], ['asc']).map((nasted_item) => (
                      <li>
                        <a href={nasted_item.link} target={nasted_item.target}>{nasted_item.name}</a>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <a href={item.link} target={item.target}>{item.name}</a>
              )}
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

export default MobileMenu;
