import React, { Fragment, useEffect, useContext } from 'react';
import Footer from '../components/Footer';
import DataContext from '../context/dataContext';
import ReactFullpage from '@fullpage/react-fullpage';
import RegisterForm from '../components/RegisterForm';
import PromoBlogs from '../components/PromoBlogs';
import { InView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';

const Blogs = ({ location }) => {
  const dataContext = useContext(DataContext);
  const { getStaticPagesByBlog, staticPagesByBlog, filteredState } = dataContext;
  useEffect(() => {
    getStaticPagesByBlog();
    console.warn("staticPagesByBlog >> ", staticPagesByBlog);
    // getFilteredBlogs();
    // eslint-disable-next-line
  }, [filteredState]);
  return (
    <Fragment>
      <ReactFullpage
        //fullpage options
        licenseKey={'E2D9F03D-718B42A6-8BAC5EBE-085C8480'}
        navigation={true}
        navigationPosition='right'
        // lockAnchors={true}
        verticalCentered={false}
        responsiveWidth={0}
        scrollingSpeed={700} /* Options here */
        lazyLoading={true}
        anchors={_.map(staticPagesByBlog, blog => blog.slug)}
        render={({ state, fullpageApi }) => {
          return (
            <InView>
              <div className='promo-slider'>
                {staticPagesByBlog?.map((blog) => (
                  <div className='section fp-auto-height' key={blog.id}>
                    <PromoBlogs
                      fullpageApi={fullpageApi}
                      name={blog.name}
                      location={blog.location}
                      slug={blog.slug}
                      blog={blog}
                      description={blog.description}
                      stream_id={blog.stream_id}
                      cover={blog.cover}
                    />
                  </div>
                ))}
                <div className='section fp-auto-height' id='section-footer'>
                  <RegisterForm />
                  <Footer />
                </div>
              </div>
            </InView>
          );
        }}
      />
    </Fragment>
  );
};

export default Blogs;
